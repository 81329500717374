import React from 'react';
import {Box, Flex, FlexItem} from "@bigcommerce/big-design";
import styles from './footer.module.scss'

const FooterComponent = () => {
    return <Box backgroundColor="white" shadow="raised" className={styles.footer}>
        <Flex justifyContent="center" alignItems="center" paddingLeft="medium" paddingRight="medium"
              className={styles.flexBox}>
            <FlexItem className={styles.flexItem}>
                &copy; <a href="https://bcapps.dev" target="_blank" rel="noreferrer">BC Apps</a>
            </FlexItem>
            <FlexItem className={styles.flexItem}>
                <a href="https://bcapps.dev/terms.html" target="_blank" rel="noreferrer">Terms & Conditions</a>
            </FlexItem>
            <FlexItem className={styles.flexItem}>
                <a href="mailto:contact@bcapps.dev" target="_blank" rel="noreferrer">Contact Us</a>
            </FlexItem>
        </Flex>
    </Box>
};

export default FooterComponent;