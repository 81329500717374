import {FC, useEffect, useState} from 'react';
import MainLayout from "../../layouts/main.layout";
import {
    AlertProps,
    Box,
    Button,
    CheckboxLabel,
    Flex,
    FlexItem,
    FormGroup,
    H2,
    H3,
    H4,
    Pagination,
    ProgressBar,
    Switch,
    Table,
    Tabs
} from "@bigcommerce/big-design";
import {ArrowDownwardIcon} from "@bigcommerce/big-design-icons";
import {ReduxStoreInterface} from "../../../redux-store/store";
import {connect} from "react-redux";
import {ChannelInterface, SiteInterface} from "../../layouts/main.interface";
import {BackupInterface} from "./dashboard.interface";
import {fetchBackups, fetchDownloadURL, scheduleBackups} from "./dashboard.service";
import {alertsManager} from "../../../index";

interface Props {
    site?: SiteInterface;
    channels?: ChannelInterface[];
}

const formatDate = (dateString: string) => {
    return new Date(dateString).toDateString()
}

const DashboardComponent: FC<Props> = ({site, channels}) => {

    const [activeTab, setActiveTab] = useState('backup_list');
    const [loading, setLoading] = useState(false);
    const [loadingBackupNow, setLoadingBackupNow] = useState(false);

    const [backups, setBackups] = useState<BackupInterface[]>([]);
    const [ranges] = useState([8, 16, 24]);
    const [range, setRange] = useState(ranges[0]);
    const [page, setPage] = useState(1);
    const [totalBackups, setTotalBackups] = useState(0);
    const [disableDownload, setDisableDownload] = useState(false);

    const refreshBackups = () => {
        if (site?.channel_id) {
            setLoading(true);
            fetchBackups(site.channel_id, page, range)
                .then(result => {
                    if (result.status && result.data?.data) {
                        setTotalBackups(result.data.total);
                        setBackups(result.data?.data);
                        setLoading(false);
                    }
                });
        }
    }

    useEffect(() => {
        refreshBackups()
    }, [site, page, range]);

    const downloadBackup = (backup_id: number) => {
        setDisableDownload(true);
        fetchDownloadURL(`store/channels/backups/download/${backup_id}`)
            .then(result => {
                if (result.status) {
                    window.open(result.data, "_blank")
                } else {
                    const alert = {
                        header: 'There was an error!',
                        messages: [
                            {
                                text: result.message
                            },
                        ],
                        type: result.status ? 'success' : 'error',
                        onClose: () => null,
                    } as AlertProps;
                    alertsManager.add(alert)
                }
                setDisableDownload(false);
            })
    }

    const backupNow = () => {
        if (site?.channel_id) {
            setLoadingBackupNow(true);
            scheduleBackups(site?.channel_id)
                .then(result => {
                    setLoadingBackupNow(false);
                    const alert = {
                        header: 'Backup initialized',
                        messages: [
                            {
                                text: result.message
                            },
                        ],
                        type: result.status ? 'success' : 'error',
                        onClose: () => null,
                    } as AlertProps;
                    alertsManager.add(alert)
                })
        }
    }

    const items = [
        {id: 'backup_list', title: 'Backup List'},
        // {id: 'settings', title: 'Settings'},
    ];

    const Backups = () =>
        <>
            <div style={{visibility: loading ? 'visible' : 'hidden'}}><ProgressBar/></div>
            {
                backups.length > 0 ?
                    <Box marginBottom="medium">
                        <Table
                            columns={[
                                {header: 'UID', hash: 'uid', render: ({id}) => id},
                                {header: 'Backup Name', hash: 'name', render: ({name}) => name},
                                {
                                    header: 'Backup Timestamp',
                                    hash: 'date',
                                    render: ({created_at}) => formatDate(created_at)
                                },
                                {header: 'Backup Type', hash: 'type', render: ({type}) => type.toUpperCase()},
                                {
                                    header: 'Action',
                                    hash: 'action',
                                    render: ({id}) => <Button
                                        onClick={() => downloadBackup(id)} variant="secondary"
                                        disabled={disableDownload}
                                        iconLeft={<ArrowDownwardIcon/>}>Download</Button>
                                },
                            ]}
                            items={backups}
                            stickyHeader
                        />
                    </Box> : <>
                        <Flex style={{height: '200px'}} justifyContent="center" alignItems="center">
                            <FlexItem>
                                <H4>No Backups found!</H4>
                            </FlexItem>
                        </Flex>
                    </>
            }
            {
                !loading && <Pagination
                    currentPage={page}
                    itemsPerPage={range}
                    itemsPerPageOptions={ranges}
                    totalItems={totalBackups}
                    onPageChange={(newPage) => setPage(newPage)}
                    onItemsPerPageChange={(newRange) => {
                        setPage(1);
                        setRange(newRange);
                    }}
                />
            }
        </>;

    const getChannelName = () => {
        const channel = channels?.filter(channel => channel?.id === site?.channel_id);
        if (channel?.length === 1) {
            return channel[0].name;
        }
        return '';
    }

    const Settings = () =>
        <Box margin="medium">
            <H3>{getChannelName()} Settings</H3>
            <FormGroup>
                <CheckboxLabel>Daily Backup</CheckboxLabel>
                <Switch/>
            </FormGroup>
        </Box>;

    return <MainLayout header footer>
        <H2>Backup Site ({site?.url})</H2>
        <Box padding="medium" shadow="raised" backgroundColor="white">
            <Flex justifyContent="space-between">
                <FlexItem>
                    <Tabs activeTab={activeTab} items={items} onTabClick={setActiveTab}/>
                </FlexItem>
                <FlexItem>
                    {
                        activeTab === "backup_list" &&
                        <Button onClick={() => backupNow()}
                                isLoading={loadingBackupNow}>Backup Now</Button>
                    }
                    {
                        activeTab === "backup_list" &&
                        <Button onClick={() => refreshBackups()} isLoading={loading}>Refresh</Button>
                    }
                </FlexItem>
            </Flex>
            <hr/>
            {
                activeTab === 'backup_list' && <Backups/>
            }
            {
                activeTab === 'settings' && <Settings/>
            }
        </Box>
    </MainLayout>
};

const mapStateToProps = (state: ReduxStoreInterface) => ({
    site: state.main.selected_site,
    channels: state.main.store?.channels
})

export default connect(mapStateToProps)(DashboardComponent);