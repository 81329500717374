import {Route, Routes} from "react-router-dom";
import DashboardComponent from "./app/pages/dashboard/dashboard.component";
import {useEffect, useState} from "react";
import {fetchStore} from "./App.service";
import store from "./redux-store/store";
import {setStore} from "./app/layouts/main.reducer";
import LoadingLayout from "./app/layouts/loading.layout";

const App = () => {

    const [loading, setLoading] = useState(true);

    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        fetchStore().then(result => {
            if (result.status) {
                store.dispatch(setStore(result.data?.store));
                setLoading(false);
            } else {
                setRestricted(true);
            }
        })
    }, [])

    if (restricted)
        return <h1 style={{textAlign: 'center'}}>
            Session time is out, Please refresh the page!
        </h1>

    if (loading)
        return <LoadingLayout/>

    return <Routes>
        <Route path="/" element={<DashboardComponent/>}/>
    </Routes>
};

export default App;
