import {createSlice} from "@reduxjs/toolkit";
import {SiteInterface, StoreInterface} from "./main.interface";

export interface MainStateInterface {
    store?: StoreInterface,
    selected_site?: SiteInterface
}

export const initialState: MainStateInterface = {}

export const mainSlice = createSlice({
    name: 'mainReducer',
    initialState,
    reducers: {
        setSite(state, action) {
            console.log('action.payload', action.payload);
            state.selected_site = action.payload
        },
        setStore(state, action) {
            state.store = action.payload
        }
    }
});

export const {setStore, setSite} = mainSlice.actions

export default mainSlice.reducer