import {Box, Flex, FlexItem, Select} from "@bigcommerce/big-design";
import styles from './header.module.scss';
import {FC, useEffect, useState} from "react";
import store, {ReduxStoreInterface} from "../../../redux-store/store";
import {connect} from "react-redux";
import {StoreInterface} from "../main.interface";
import {SelectOption} from "@bigcommerce/big-design/dist/components/Select/types";
import {setSite} from "../main.reducer";
import Logo from '../../../assets/logo.png';

interface Props {
    bc_store?: StoreInterface;
}

const HeaderComponent: FC<Props> = ({bc_store}) => {

    const [selectedSite, setSelectedSite] = useState<number>();
    const [sites, setSites] = useState<SelectOption<number>[]>([]);

    useEffect(() => {
        const tempSites: SelectOption<number>[] = [];
        bc_store?.channels.forEach(channel => {
            tempSites.push({
                value: channel.site.bc_site_id,
                content: channel.name
            })
        });
        setSites(tempSites);
        bc_store?.channels.forEach(channel => {
            // Select first as default selected
            store.dispatch(setSite(channel.site))
            setSelectedSite(channel.site.bc_site_id);
        })
    }, [bc_store])

    return <Box backgroundColor="white" shadow="raised" className={styles.header}>
        <Flex justifyContent="space-between" alignItems="center" paddingLeft="medium" paddingRight="medium"
              className={styles.flexBox}>
            <FlexItem style={{height: '56px', display: 'inline'}}>
                <a href="https://bcapps.dev" target="_blank" style={{color: 'black'}} rel="noreferrer">
                    <img style={{
                        margin: '3px -40px 0 -40px',
                        height: '50px',
                        display: 'inline-block',
                        position: 'relative',
                        top: 0,
                        left: 0
                    }} src={Logo} alt=""/>
                    <h2 style={{
                        display: "inline-block",
                        position: 'absolute',
                        margin: 0,
                        height: '56px',
                        lineHeight: '56px'
                    }}>Theme Backup</h2>
                </a>
            </FlexItem>
            <FlexItem>
                <Select
                    onOptionChange={(value) => {
                        const channel = bc_store?.channels.filter(channel => channel.site.bc_site_id === value);
                        if (channel?.[0]) {
                            store.dispatch(setSite(channel[0].site))
                            setSelectedSite(value);
                        }
                    }}
                    options={sites}
                    placeholder={'Select site'}
                    placement={'bottom-start'}
                    required
                    value={selectedSite}
                />
            </FlexItem>
        </Flex>
    </Box>
};

const mapStateToProps = (state: ReduxStoreInterface) => ({
    bc_store: state.main.store,
});

export default connect(mapStateToProps)(HeaderComponent)