import {configureStore} from "@reduxjs/toolkit";
import mainReducer, {MainStateInterface} from "../app/layouts/main.reducer";

export interface ReduxStoreInterface {
    main: MainStateInterface
}

export default configureStore<ReduxStoreInterface>({
    reducer: {
        main: mainReducer
    }
})
