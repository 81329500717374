import Request, {PaginateResponse, responseValidator, ResultInterface} from "../../utils/request";
import {BackupInterface} from "./dashboard.interface";

export const fetchBackups = (channel: number, page: number, per_page: number) =>
    Request.get<ResultInterface<PaginateResponse<BackupInterface[]>>>(`store/channels/${channel}/backups`, {
        params: {
            page: page,
            per_page: per_page,
        }
    }).then(responseValidator)

export const scheduleBackups = (channel: number) =>
    Request.get<ResultInterface<null>>(`store/channels/${channel}/backups/schedule`).then(responseValidator)

export const fetchDownloadURL = (fetch_url: string) =>
    Request.get<ResultInterface<string>>(fetch_url).then(responseValidator)
