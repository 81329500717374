import {FC} from 'react';
import {Box} from "@bigcommerce/big-design";
import styles from './main.module.scss';
import HeaderComponent from "./components/header.component";
import {ReduxStoreInterface} from "../../redux-store/store";
import {connect} from "react-redux";
import {StoreInterface} from "./main.interface";
import FooterComponent from "./components/footer.component";

interface Props {
    header?: boolean;
    footer?: boolean;
    store?: StoreInterface
}

const MainLayout: FC<Props> = ({header, footer, store, children}) => {
    return <Box backgroundColor="secondary20" className={styles.mainBox}>
        {header && <HeaderComponent/>}
        <Box padding="medium" margin="medium" style={{marginBottom: '60px'}}>
            {children}
        </Box>
        {footer && <FooterComponent/>}
    </Box>
};

const mapStateToProps = (state: ReduxStoreInterface) => ({
    store: state.main.store
});

export default connect(mapStateToProps)(MainLayout);