import axios, {AxiosResponse} from "axios";

const axiosInstant = () => {
    const axiosInstant = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            "Access-Control-Allow-Origin": "*"
        }
    });

    axiosInstant.interceptors.response.use(value => value, error => {
        // window.location.reload();
    });

    return axiosInstant;
}

const Request = axiosInstant();

export interface ResultInterface<T> {
    status: boolean;
    message: string;
    data: T
}

export interface PaginateResponse<T> {
    total: number;
    data: T;
}

export const responseValidator = function <T>(response: AxiosResponse<ResultInterface<T>>) {
    if (response.data) {
        if (response.data.status) {
            return {
                status: true,
                message: response.data.message,
                data: response.data.data
            }
        } else {
            return {
                status: false,
                message: response.data.message
            }
        }
    } else {
        return {
            status: false,
            message: 'There was an error!'
        }
    }
}

export default Request