import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AlertsManager, createAlertsManager, GlobalStyles} from "@bigcommerce/big-design";
import {Provider} from "react-redux";
import store from "./redux-store/store";

export const alertsManager = createAlertsManager();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <GlobalStyles/>
            <App/>
            <AlertsManager manager={alertsManager}/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
