import {Flex, H0} from "@bigcommerce/big-design";
import {useEffect, useState} from "react";

const LoadingLayout = () => {

    const [longTime, setLongTime] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLongTime(true);
        }, 30000)
    }, [])

    return <Flex justifyContent="center" alignItems="center" style={{height: '100vh'}}>
        <H0>Loading Please wait...</H0>
        <br/>
        {
            longTime && <p>If page taking too much time, Please refresh!</p>
        }
    </Flex>
};

export default LoadingLayout;